import { selectState } from '../state/reducers/selectState'
import { takeGaurd } from 'kat/lib/utilities/takeGaurd'
import { put, select } from 'redux-saga/effects'
import { StateReducers } from '../state/reducers/types/StateReducers'
import { getSearchTextResults } from 'utilities/get/getSearchTextResults'
import { SearchTaskItemsAction } from 'actionCreators/SearchTaskItemsAction'
import { SearchTaskItemsDoneAction } from 'actionCreators/SearchTaskItemsDoneAction'

const description = 'searchTaskFlow'

export const searchTaskItemsFlow = function* () {
  while (1) {
    const action: SearchTaskItemsAction =
      yield takeGaurd<SearchTaskItemsAction>('SEARCH_TASK_ITEMS_ACTION')
    const state: StateReducers = yield select(selectState)
    const task = state.currentTask?.data

    if (action.searchText && task) {
      const searchTextResults = getSearchTextResults({
        searchTasks: state.tasks || undefined,
        searchText: action.searchText,
        searchTaskStatuses: state.currentWorkspace?.taskStatuses,
        searchTask: {
          task,
          taskId: action.taskId,
        },
      })

      yield put<SearchTaskItemsDoneAction>({
        type: 'SEARCH_TASK_ITEMS_DONE_ACTION',
        description,
        results: searchTextResults,
        taskId: action.taskId,
        searchText: action.searchText,
        clear: false,
      })
    } else {
      yield put<SearchTaskItemsDoneAction>({
        type: 'SEARCH_TASK_ITEMS_DONE_ACTION',
        description,
        results: undefined,
      })
    }
  }
}
