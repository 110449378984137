import { Task } from 'functions/types/Task'
import { TaskItem } from 'functions/types/TaskItem'
import { Workspace } from 'functions/types/Workspace'
import { TasksReducer } from 'state/reducers/types/TasksReducer'
import { GenericObject } from 'types/GenericObject'
import { ssSearchMatchesAllSearchWords } from 'utilities/ssSearch'

interface Props {
  searchText: string
  searchTask?: {
    taskId: string
    task: Task
  }
  searchTasks?: TasksReducer
  searchTaskStatuses?: Workspace['taskStatuses']
}

interface SearchData {
  taskId?: string
  taskStatus?: string
  summary?: string
  taskItemSummaries?: string[]
}

export const getSearchTextResults = ({
  searchText,
  searchTask,
  searchTasks,
  searchTaskStatuses,
}: Props) => {
  const searchDataMutable: SearchData[] = []

  const searchTextLowered = searchText.toLowerCase()

  const searchableTasksMutable: GenericObject<Task> = {}
  if (searchTask) {
    searchableTasksMutable[searchTask.taskId] = searchTask.task
  } else {
    for (const [taskId, taskReducer] of Object.entries(searchTasks || {})) {
      searchableTasksMutable[taskId] = taskReducer.data
    }
  }

  for (const [taskId, task] of Object.entries(searchableTasksMutable)) {
    const { summary, taskItems } = task

    const taskItemSummaries =
      taskItems?.map(({ summary: taskItemSummary }) => {
        return taskItemSummary || ''
      }) || []

    searchDataMutable.push({
      taskId,
      summary: summary || '',
      taskItemSummaries,
    })
  }

  if (searchTaskStatuses) {
    searchTaskStatuses?.forEach((taskStatus) => {
      searchDataMutable.push({
        taskStatus,
      })
    })
  }

  const results: SearchData[] = ssSearchMatchesAllSearchWords(
    searchDataMutable,
    ['summary', 'taskItemSummaries', 'taskStatus'],
    searchTextLowered
  )

  const searchTextResult = results
    .slice(0, 15)
    .map(({ taskId, taskStatus }) => {
      if (taskId) {
        const taskItemSummariesResults: TaskItem[] =
          ssSearchMatchesAllSearchWords(
            searchableTasksMutable[taskId].taskItems || [],
            ['summary'],
            searchTextLowered
          )

        const taskItemIdsMutable: string[] = []

        taskItemSummariesResults.forEach(({ id }) => {
          if (id) {
            taskItemIdsMutable.push(id)
          }
        })

        return {
          taskId,
          taskItemIds: taskItemIdsMutable,
        }
      }
      return {
        taskStatus,
      }
    })

  return searchTextResult
}
