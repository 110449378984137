import { EnvConfigBase } from 'kat/lib/envConfig/types/EnvConfigBase'
import { envConfigBase } from 'kat/lib/envConfig/envConfigBase'
import { envConfigBaseDefault } from 'kat/lib/envConfig/envConfigBaseDefault'
import { EnvConfig } from './types/EnvConfig'

const env = process.env

let environment = envConfigBaseDefault.environment

if (env.REACT_APP_ENV === 'jsdom') {
  environment = 'jsdom'
}
if (env.REACT_APP_ENV === 'local') {
  environment = 'local'
}
if (env.REACT_APP_ENV === 'development') {
  environment = 'development'
}
if (env.REACT_APP_ENV === 'beta') {
  environment = 'beta'
}
if (env.REACT_APP_ENV === 'production') {
  environment = 'production'
}

const appName = env.REACT_APP_NAME === 'NotePals' ? 'NotePals' : 'EggKat'
const appVersion = '105' // use basic numbers because it will be converted to number
const buildTime = env.REACT_APP_BUILD_TIME || envConfigBaseDefault.buildTime
const publicUrl =
  env.REACT_APP_EGGKAT_PUBLIC_URL ||
  'http://192.168.0.110:3003' ||
  envConfigBaseDefault.publicUrl
const feedbackWorkspaceId = env.FEEDBACK_WORKSPACE_ID || 'EzYnVWpYsQtrvfmVfmVw'

const professionalPricePerUser = Number(
  env.REACT_APP_PROFESSIONAL_PRICE_PER_USER || 2.99
)

const pageSizeLimitFree = Number(env.REACT_APP_PAGE_SIZE_LIMIT_FREE || 50)
const pageSizeLimitPro = Number(env.REACT_APP_PAGE_SIZE_LIMIT_PRO || 100)
const aiRequestLimitFree = Number(env.REACT_APP_AI_REQUEST_LIMIT_FREE || 40)
const aiRequestLimitPro = Number(env.REACT_APP_AI_REQUEST_LIMIT_PRO || 200)
const maxViewHistoryCount = Number(env.REACT_APP_MAX_VIEW_HISTORY_COUNT || 100)
const maxTaskIdWatchers = Number(env.REACT_APP_MAX_TASK_ID_WATCHERS || 5)
const maxPlanTaskCountFree = Number(
  env.REACT_APP_MAX_PLAN_TASK_COUNT_FREE || 10
)
const maxPlanTaskCountPro = Number(env.REACT_APP_MAX_PLAN_TASK_COUNT_PRO || 20)
const maxOpenPlanCount = Number(env.REACT_APP_MAX_OPEN_PLAN_COUNT || 2) // The number of plans that can be status open
const getMessagesLimit = Number(env.REACT_APP_GET_MESSAGES_LIMIT || 15)

const uploadCapacityGigabytesPerUserPro = Number(
  env.REACT_APP_UPLOAD_CAPACITY_GIGABYTES_PER_USER_PRO ||
    envConfigBaseDefault.uploadCapacityGigabytesPerUserPro
)

const uploadCapacityGigabytesPerUserFree = Number(
  env.REACT_APP_UPLOAD_CAPACITY_GIGABYTES_PER_USER_FREE ||
    envConfigBaseDefault.uploadCapacityGigabytesPerUserFree
)

const maxMessagingTokens = Number(
  env.REACT_APP_MAX_MESSAGING_TOKENS || envConfigBaseDefault.maxMessagingTokens
)
const descriptionMaxLength = Number(
  env.REACT_APP_DESCRIPTION_MAX_LENGTH ||
    envConfigBaseDefault.descriptionMaxLength
)

const workingStaleSeconds = Number(
  env.REACT_APP_WORKING_STALE_SECONDS ||
    envConfigBaseDefault.workingStaleSeconds
)

const workspaceMaxUserCountFree = Number(
  env.REACT_APP_WORKSPACE_MAX_USER_COUNT_FREE ||
    envConfigBaseDefault.workspaceMaxUserCountFree
)

const workspaceMaxUserCountPro = Number(
  env.REACT_APP_WORKSPACE_MAX_USER_COUNT_PRO ||
    envConfigBaseDefault.workspaceMaxUserCountPro
)

// const subscriptionEndDeferalSeconds = Number(
//   env.REACT_APP_SUBSCRIPTION_END_DEFERAL_SECONDS ||
//     envConfigBaseDefault.subscriptionEndDeferalSeconds
// )

interface InitializeEnvConfigProps {
  appName?: EnvConfigBase['appName']
}

const iosAppStoreId = '1559109922'
const androidPackageName = 'com.eggkat'

const envConfigMutable: EnvConfig = {
  ...envConfigBaseDefault,
  environment,
  appName,
  appVersion,
  buildTime,
  publicUrl,
  googlePlayUrl: `https://play.google.com/store/apps/details?id=${androidPackageName}&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1`,
  appStoreUrl: `https://apps.apple.com/us/app/eggkat/id${iosAppStoreId}?itsct=apps_box_badge&amp;itscg=30200`,
  uploadCapacityGigabytesPerUserPro,
  uploadCapacityGigabytesPerUserFree,
  maxMessagingTokens,
  descriptionMaxLength,
  workingStaleSeconds,
  feedbackWorkspaceId,
  workspaceMaxUserCountFree,
  workspaceMaxUserCountPro,
  taskItemNotificationTrottleMs: 3600000, // 1 hour
  // subscriptionEndDeferalSeconds,
  professionalPricePerUser,
  pageSizeLimitFree,
  pageSizeLimitPro,
  aiRequestLimitFree,
  aiRequestLimitPro,
  maxViewHistoryCount,
  maxTaskIdWatchers,

  // the following configs is only used for new workspaces and should not be used for logic on current workspaces
  maxPlanTaskCountFree,
  maxPlanTaskCountPro,
  // end
  maxOpenPlanCount,
  getMessagesLimit,
  enableWorkspaceLogo: false,
  androidPackageName,
  iosBundleId: 'org.lasirenafrida.eggkat',
  iosAppStoreId,
  enableReaderRole: false,
  uploadImageMaxWidthOrHeight: 1800,
  features: {
    autoEmojiTitle: ['local', 'development'],
  },
}

envConfigBase.extends(envConfigMutable)

export const initializeEnvConfig = ({ appName }: InitializeEnvConfigProps) => {
  if (appName === 'NotePals') {
    envConfigMutable.appName = 'NotePals'
    envConfigMutable.androidPackageName = 'com.notepals'
    envConfigMutable.iosBundleId = 'org.lasirenafrida.couplepad'
    envConfigMutable.iosAppStoreId = '1625524804'

    envConfigMutable.publicUrl =
      env.REACT_APP_NOTEPALS_PUBLIC_URL || 'http://192.168.0.110:3004'

    envConfigMutable.googlePlayUrl = `https://play.google.com/store/apps/details?id=${envConfigMutable.androidPackageName}&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1`
    envConfigMutable.appStoreUrl = `https://apps.apple.com/us/app/eggkat/id${envConfigMutable.iosAppStoreId}?itsct=apps_box_badge&amp;itscg=30200`
  }

  envConfigBase.extends(envConfigMutable)
}

export const envConfig = () => {
  return envConfigMutable
}

export const isFeatureEnabled = (featureName: keyof EnvConfig['features']) => {
  return envConfig().features[featureName].includes(envConfig().environment)
}
