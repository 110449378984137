import { SearchTextDoneAction } from '../actionCreators/SearchTextDoneAction'
import { selectState } from './../state/reducers/selectState'
import { takeGaurd } from 'kat/lib/utilities/takeGaurd'
import { put, select } from 'redux-saga/effects'
import { SearchTextAction } from '../actionCreators/SearchTextAction'
import { StateReducers } from '../state/reducers/types/StateReducers'
import { getSearchTextResults } from 'utilities/get/getSearchTextResults'

const description = 'searchTextFlow'

export const searchTextFlow = function* () {
  while (1) {
    const action: SearchTextAction = yield takeGaurd<SearchTextAction>(
      'SEARCH_TEXT_ACTION'
    )
    const state: StateReducers = yield select(selectState)

    if (action.searchText) {
      const searchTextResults = getSearchTextResults({
        searchTasks: state.tasks || undefined,
        searchText: action.searchText,
        searchTaskStatuses: state.currentWorkspace?.taskStatuses,
      })

      yield put<SearchTextDoneAction>({
        type: 'SEARCH_TEXT_DONE_ACTION',
        description,
        results: searchTextResults,
        searchText: action.searchText,
      })
    } else {
      yield put<SearchTextDoneAction>({
        type: 'SEARCH_TEXT_DONE_ACTION',
        description,
        results: undefined,
      })
    }
  }
}
